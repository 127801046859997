  <template>
  <div>
    <div class="editor-wrap">
      <div class="sidebar_wrap show">
        <div class="b-sidebar">
          <h4>Items</h4>
          <draggable
            class="dragArea list-group"
            :list="modules"
            :group="{ name: 'people', pull: 'clone', put: false }"
            :clone="cloneItem"
          >
            <div
              class="list-group-item"
              v-for="element in modules"
              :key="element.name"
            >
              {{ element.name }}
            </div>
          </draggable>
          <button @click="save" class="save__btn">Save</button>
        </div>
      </div>
      <div class="builder-wrap">
        <div class="heading-primary">Edit Pain Point</div>
        <div class="promo-title">
          <label>Title - ( {{slug}} )</label>
          <input type="text" name="PainPointTitle" v-model="title">
          <div class="top-inputs">
            <div class="sm">
              <label>Order</label>
              <input type="number" v-model="order" class="input-xs" />
            </div>
            <div>
              <label>Point Icon <i :class="[this.icon]"></i></label>
              <input type="text" v-model="icon" class="input-sm" />
            </div>
            <div class="promo-preview">
              <label>Promo Image Preview (600px * 338px)</label>
              <div v-if="promoimageData!=null">
                Progress: {{uploadPromoValue.toFixed()+"%"}}
                <progress id="promoprogress" :value="uploadPromoValue" max="100" ></progress>
              </div>
              <div v-if="promopic!=null" style="margin-top: 15px;">
                <img :src="promopic" class="icon-preview">
                <span @click="delPromoIMG" class="delPromoIMG">DEL</span>
              </div>
              <input  id="uploadpromo" type="file" @change="previewPromoImage" accept="image/*" ref="promofileupload" />
            </div>
          </div>
          
          <h3>Content Builder</h3>
          <div class="builder">
            <div class="b-content">
              <draggable
                class="dragArea list-group"
                :list="pagecontent"
                group="people" 
                handle=".handle"
              >
                <div
                  class="list-group-item"
                  v-for="(element, idx) in pagecontent"
                  :key="idx"
                >
                  <i class="fa fa-align-justify handle"></i>
                  <div class="card-title">{{ element.name }}</div>
                  <input type="text" v-if="element.type === 'title'" v-model="element.text" />
                  <textarea v-if="element.type === 'textarea'" v-model="element.text" ></textarea>
                  <label v-if="element.type === 'iconcard'"># of Columns</label>
                  <select v-if="element.type === 'iconcard'" v-model="element.cols">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  <draggable
                    class="dragArea icon-wrap" :class="'col-' + [element.cols]"
                    :list="element.items"
                    group="child"
                    handle=".handle"
                  >
                    <div
                      class="icon-items"
                      v-for="(it, cidx) in element.items"
                      :key="cidx"

                    > 
                      <div class="icon-items-inner">
                        <i class="fa fa-align-justify handle"></i>
                        <label>Font Awesome Class - (<i :class="[it.icon]"></i>)</label>
                        <input type="text" v-model="it.icon" />
                        <label>HTML</label>
                        <textarea type="text" v-model="it.text"></textarea>
                        <label>Url</label>
                        <input type="text" v-model="it.link" />
                        <i class="fa fa-times item-del" @click="removeChildAt(idx,cidx)"></i>
                      </div>
                    </div>
                  </draggable>
                  <button v-if="element.items" class="" @click="add(idx)" >Add</button>
                  <i class="fa fa-times item-del" @click="removeAt(idx)"></i>
                </div>
              </draggable>
            </div>
          </div>
        </div>
        <button @click="save" class="save__btn">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import draggable from "vuedraggable";

export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Edit Painpoint'
  },
  components: {
    draggable
  },
  data() {
    return {
      keepInBounds: true,
      title: '',
      slug: '',
      icon: '',
      id: '',
      order: '',
      imageData: null,
      picture: null,
      uploadValue: 0,
      promopicture: null,
      promoimageData: null,
      uploadPromoValue: 0,
      promopic: null,
      modules: [
        { name: "Title", type: "title" },
        { name: "Textarea", type: "textarea" },
        { name: "Icon Card", type: "iconcard" }
      ],
      pagecontent: []
    }
  },
  watch: {
    title: function(val) {
      this.slug = val.toString()                           // Cast to string (optional)
        .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                                  // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-')            // Replace spaces with -
        .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -;
    }
  },
  computed: {
    
  },
  methods: {

    async save() {
      this.id = this.$route.query.id;
      firebase
      .firestore()
      .collection("painpoints")
      .doc(this.id)
      .update({
        title: this.title,
        slug: this.slug,
        icon: this.icon,
        img: this.promopic,
        order: this.order,
        content: JSON.stringify(this.pagecontent)
      }).then((response) => {
        let myToast = this.$toasted.show("Saved",{
          position: "top-center"
        });
        myToast.goAway(1000);
      }).catch((error) => {
        this.errors = error
      })
    },
    loadData: function() {
      this.id = this.$route.query.id;
      firebase
      .firestore()
      .collection("painpoints")
      .doc(this.id)
      .get().then(snapshot => {
        if (!snapshot.exists) return;
        let data = snapshot.data()
        this.title = data.title
        this.promopic = data.img
        this.slug = data.slug
        this.order = data.order
        this.icon = data.icon
        this.pagecontent = JSON.parse(data.content)
      })
    },
    removeAt: function(idx) {
      this.pagecontent.splice(idx, 1);
    },
    removeChildAt: function(idx, cidx) {
      this.pagecontent[idx].items.splice(cidx, 1);
    },
    add: function(idx) {
      this.pagecontent[idx].items.push({icon: "fa fa-check", text: "foo"});
    },
    cloneItem({name, type }) {

      if(type === "iconcard") {
        return {
          name: `${name}`,
          type: `${type}`,
          cols: "3",
          items: [
            {icon: "fa fa-check", text: "foo bar"}
          ]
        };
      } else {
        return {
          name: `${name}`,
          type: `${type}`,
        };
      }
    },
    delPromoIMG() {
      let promoimg = firebase.storage().refFromURL(this.promopic);
      promoimg.delete();
      this.promopic = ''
      this.promoimageData=null
    },
    previewPromoImage(event) {
      this.uploadPromoValue=0;
      this.promopicture=null;
      this.promoimageData = event.target.files[0];
      const storagePromoRef=firebase.storage().ref(`${this.promoimageData.name}`).put(this.promoimageData);
      storagePromoRef.on(`state_changed`,snapshot=>{
        this.uploadPromoValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadPromoValue=100;
        storagePromoRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.promopicture =url;
          this.promopic=url;
        });
      }
      );
    },
    onUpload(){
      this.picture=null;
      const storageRef=firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        });
      }
      );
    },

  },
  mounted() {
    this.loadData();
  }
}
</script>