<template>
  <div>
    <div>
      <div class="login-wrap">
        <form @submit.prevent="userForget">
          <div class="email">
            <input type="email" v-model="forgetemail" placeholder="email">
          </div>
          <button type="submit">Reset</button>
        </form>
        <div class="error" v-if="err">{{this.err}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Password Reset'
  },
  data() {
    return {
      forgetemail: '',
      err: '',
      email: ''
    }
  },
  methods: {
    userForget() {
      var self = this
      var actionCodeSettings = {
        url: 'http://localhost:8080/'
      };
      firebase.auth().sendPasswordResetEmail(self.forgetemail,actionCodeSettings).then(function() {
        self.err = "Email has been sent to your inbox."
      }).catch(function(error) {
        console.log(error.message);
        self.err = error.message;
      });
    }
  }
}
</script>